<template>
    <div class="row pt-4 mt-3">
        <div class="col-lg-3">
            <h2 class="h4">Logos</h2>
        </div>
        <div class="col-lg-9">
            <div class="border rounded-3 p-3 logo-wrapper" id="logo-dark-info">

                <div class="left">
                    <!-- Logo Dark-->
                    <div class="logo-preview">
                        <div>
                            <label class="form-label fw-bold mb-0 pb-2">
                                Logo For Use On Dark Background
                            </label>
                        </div>
                        <div>
                            <div v-if="!isLoadingTemporaryLink && logoDarkImgSrc" class="logo-container">
                                <img class="d-block" :key="`whitelabel-logo-${updateKey}`" :src="logoDarkImgSrc" width="200"
                                    alt="Logo" />
                            </div>
                            <div v-if="!isLoadingTemporaryLink && !logoDarkImgSrc">
                                No logo uploaded.
                            </div>
                        </div>
                    </div>
                    <!-- / Logo Dark -->
                </div>

                <div class="right">
                    <input type="file" class="file-input" ref="fileInput" accept="image/*" @change="onFilePicked" />

                    <button type="button" class="btn btn-primary btn-sm" @click.prevent="attemptChangeLogo">
                        Click To Upload
                    </button>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import temporaryUrls from '../../../api/temporary-urls';
import Images from '../../../mixins/Images';
import axios from 'axios';
import Forms from '../../../mixins/Forms';

import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
    props: ['whitelabelConfiguration'],
    data() {
        return {
            temporaryLink: null,
            updateKey: 1,
            isLoadingTemporaryLink: false
        }
    },
    created() {
        this.loadTemporaryLink();
    },
    mixins: [Images, Forms],
    components: {},
    computed: {
        logoDarkImgSrc() {
            if (this.temporaryLink) {
                return this.temporaryLink.url;
            }
            return null;
        }
    },
    methods: {
        reloadWhitelabelConfiguration() {
            this.$emit('reload');
        },

        loadTemporaryLink() {
            let vm = this;
            vm.isLoadingTemporaryLink = true;
            temporaryUrls.getWhiteLabelLogoDarkTemporaryUrl(this.whitelabelConfiguration.id).then(r => {
                vm.isLoadingTemporaryLink = false;
                vm.temporaryLink = r.data;
                vm.updateKey += 1;
            }).catch(e => {
                console.log(e);
                vm.isLoadingTemporaryLink = false;
                vm.temporaryLink = null;
            });
        },

        attemptChangeLogo() {
            this.$refs.fileInput.click();
        },

        onFilePicked(e) {
            let vm = this;
            const file = e.target.files[0];

            let formData = new FormData();
            formData.append('logo_dark', file);

            axios.post(window.API_BASE + '/my-whitelabel-configuration/logo-dark', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(r => {
                toast.success('Logo updated successfully');
                vm.loadTemporaryLink();
            }).catch(e => {
                vm.setAndNotifyErrors(e, 'Error updating logo');
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.file-input {
    display: none;
}

.logo-container {
    // border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-top: 5px;
    background-color: rgb(31, 27, 45);
    display: inline-block;
}

.logo-wrapper {
    display: flex;
    justify-content: space-between;

    .left {
        flex-grow: 1;
    }

    .right {
        flex-shrink: 1;
        display: flex;
        align-items: center;
    }
}
</style>
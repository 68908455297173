<template>
  <div>
    <dark-header-background></dark-header-background>
    <div class="container content-overlay mt-5 mb-md-4 py-5">
      <breadcrumb class="mb-3 mb-md-4 pt-md-3" type="light" :crumbs="crumbs"></breadcrumb>

      <!-- Loading -->
      <div v-if="isLoading || !whitelabelConfiguration"
        class="bg-light shadow-sm rounded-3 p-4 p-md-5 mb-2 text-center loading-section">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <!-- / Loading -->

      <!-- Card - Not Loading -->
      <div v-if="!(isLoading || !whitelabelConfiguration)" class="bg-light shadow-sm rounded-3 p-4 p-md-5 mb-2">
        <section-header></section-header>

        <company-details :whitelabel-configuration="whitelabelConfiguration"
          @reload="loadWhitelabelConfigurationSilently"></company-details>

        <logos :whitelabel-configuration="whitelabelConfiguration"></logos>

        <colours :whitelabel-configuration="whitelabelConfiguration"></colours>
      </div>
      <!-- / Card - Not Loading -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '../../components/Breadcrumb.vue'
import DarkHeaderBackground from "../../components/DarkHeaderBackground.vue"
import { useToast } from 'vue-toastification'
import SectionHeader from './Edit/SectionHeader.vue'
import CompanyDetails from './Edit/CompanyDetails.vue'
import myWhitelabelConfiguration from '../../api/my-whitelabel-configuration'
import Logos from './Edit/Logos.vue'
import Colours from './Edit/Colours.vue'

const toast = useToast();

export default {
  components: { DarkHeaderBackground, Breadcrumb, SectionHeader, CompanyDetails, Logos, Colours },
  data() {
    return {
      crumbs: [
        {
          href: '/',
          label: 'Home'
        },
        {
          href: '#',
          label: 'Whitelabel Configuration',
          active: true
        }
      ],

      isLoadingWhitelabelConfiguration: false,
      whitelabelConfiguration: null
    }
  },
  computed: {
    ...mapGetters('auth', {
      canEditWhitelabelConfiguration: 'canEditWhitelabelConfiguration'
    }),

    isLoading() {
      return this.isLoadingWhitelabelConfiguration;
    }
  },
  methods: {
    loadWhitelabelConfiguration() {
      let vm = this;
      vm.isLoadingWhitelabelConfiguration = true;
      myWhitelabelConfiguration.fetch().then(r => {
        vm.whitelabelConfiguration = r.data;
      }).catch(e => {
        toast.error('There was an error loading your whitelabel configuration.');
      }).finally(() => {
        vm.isLoadingWhitelabelConfiguration = false;
      })
    },

    loadWhitelabelConfigurationSilently() {
      let vm = this;
      myWhitelabelConfiguration.fetch().then(r => {
        vm.whitelabelConfiguration = r.data;
      }).catch(e => {
        toast.error('There was an error loading your whitelabel configuration.');
      });
    }
  },
  created() {
    if (!this.canEditWhitelabelConfiguration) {
      toast.error('You are not authorized to access this page.');
      this.$router.push('/');
    } else {
      this.loadWhitelabelConfiguration();
    }
  }
}
</script>

<style scoped lang="scss">
.loading-section {
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
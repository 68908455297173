<template>
  <div class="row pt-4 mt-3">
    <div class="col-lg-3">
      <h2 class="h4">Company Details</h2>
    </div>
    <div class="col-lg-9">
      <div class="border rounded-3 p-3" id="auth-info">
        <!-- Name-->
        <div class="">
          <editable-field
            property="display_name"
            name="Display Name"
            :initial-value="whitelabelConfiguration.display_name"
            endpoint="my-whitelabel-configuration"
            type="text"
            @updated="reloadWhitelabelConfiguration"
          ></editable-field>
        </div>
        <!-- / Name -->
      </div>
    </div>
  </div>
</template>

<script>
import EditableField from "../../../components/Display/EditableField.vue";
export default {
  props: ['whitelabelConfiguration'],
  components: { EditableField },
  computed: {
  },
  methods: {
    reloadWhitelabelConfiguration() {
      this.$emit('reload');
    }
  }
};
</script>

<style>
</style>